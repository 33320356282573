<script>
export default {
  name: 'MagazineSlide',
  props: { devise: { type: Object, default: () => ({}) } },

  data() {
    return {
      showMovie: false,
    }
  },
}
</script>

<template>
  <div class="w-full h-[85vh] lg:h-[70vh]">
    <VoixMedia v-slot="slotProps" :field="devise.fields.image" background>
      <div class="bg-cover bg-center absolute inset-0 z-30 px-6 lg:px-24" :style="{ backgroundImage: `url(${slotProps.image})` }">
        <div>
          <div class="absolute z-0 bottom-0 left-0 right-0 bg-gradient-transparent-black" style="height: 100%" />
          <div
            v-if="devise.fields.wistiaId.value"
            class="absolute inset-0 z-10 text-white flex justify-center items-center cursor-pointer"
          >
            <div
              class="rounded-full flex justify-center items-center -mt-8" style="
              height: 3rem;
              width: 3rem;
              box-shadow: 0 0 1px 0px white inset, 0 0 1px 1px white;
            " @click="showMovie = true"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-4 text-white" viewBox="0 0 30.594 31.625">
                <path style="fill: currentcolor" d="M62,66V96L91,81Z" transform="translate(-61.5 -65.188)" />
              </svg>
            </div>
          </div>
          <div
            v-if="devise.fields.wistiaId.value && showMovie"
            class="absolute inset-0 z-50 text-white bg-red-500 flex justify-center items-center"
          >
            <glue-media-wistia
              :video-id="devise.fields.wistiaId.value" relative-or-absolute="absolute"
              class=" inset-0 bg-black"
            />
          </div>
          <div
            class="absolute z-30 bottom-0 text-white mb-16 mr-6 md:mr-24 md:mb-16 lg:mb-16"
            :class="{ 'text-right right-0': devise.fields.isRight.value }" style="width: 300px"
          >
            <div class="italic font-serif text-4xl tracking-wider font-light">
              {{ devise.fields.subTitle.value }}
            </div>
            <div class="uppercase text-4xl font-sans font-light leading-snug antialiased tracking-wide">
              {{ devise.fields.title.value }}
            </div>
            <div class="text-sm font-sans2 antialiased">
              {{ devise.fields.description.value }}
            </div>
            <a
              v-if="devise.fields.link.enabled"
              class="uppercase btn-ghost text-white btn-glue-active-ghost text-xs font-normal font-sans leading-none my-8 py-4 px-8 inline-block tracking-widest" :href="devise.fields.link.value?.href" :target="devise.fields.link.value.target"
            >
              {{ devise.fields.link.value.text }}
            </a>
          </div>
        </div>
      </div>
    </VoixMedia>
  </div>
</template>
